import React from 'react'
import Home from './Components/Home/Home'
import About from './Components/About/About'
import Layout from './Components/Layout/Layout'
import Portfolio from './Components/Portfolio/Portfolio'
import Contact from './Components/Contact/Contact'

import { RouterProvider, createHashRouter } from 'react-router-dom'


let routers =createHashRouter([
    {path:"" , element: <Layout/> ,children:[
        {index:true , element: <Home/> },
        {path:"about" , element: <About/> },
        {path:"portfolio" , element: <Portfolio/>},
        {path:"contact" , element: <Contact/>}
    ]}
])


export default function App() {


  return <>

    <RouterProvider router={routers}></RouterProvider>

  </>


}
