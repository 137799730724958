import React from 'react'
import pic from  '../../images/avataaars.svg'


export default function Home() {

  document.title = "Home" ; // yro7 ll index html w y8yer l mktob fl title 

  return <>
    
    <div className='home myMargin pt-4'>
        
        <div className='container text-center pt-5 text-white'>

            <img src={pic} alt="person"/>

            <h2 className='my-3 fw-bold'>START FRAMEWORK</h2>

            <div className='mb-3 d-flex justify-content-center align-items-center'>
                <div className='shape bg-light'></div>
                <i className="fa-solid fa-star ms-2 me-2"></i>
                <div className='shape bg-light'></div>
            </div>

            <p className='pb-5 mb-0'>Graphic Artist - Web Designer - Illustrator</p>
        
       </div>
    
    </div>
  
  </>
    
  
}
