import React from 'react'
import pic1 from  '../../images/poert1.png'
import pic2 from  '../../images/port2.png'
import pic3 from  '../../images/port3.png'


export default function Portfolio() {

  document.title = "Portfolio" ; // yro7 ll index html w y8yer l mktob fl title 

  return<>
  
  <div className='portfolio myMargin pt-4'>
        
      <div className='container text-center pt-5 portoTitle'>


            <h2 className='my-3 fw-bold fs-1'>PORTFOLIO COMPONENT</h2>

            <div className='mb-3 d-flex justify-content-center align-items-center'>
                <div className='shape portoShape'></div>
                <i className="fa-solid fa-star ms-2 me-2"></i>
                <div className='shape portoShape'></div>
            </div>

            <div className='row pb-5 g-5'>

              <div className='col-md-4'>
                  <div className='item position-relative'>
                    <img src={pic1} alt="house" className='w-100 rounded-3'/>
                    <div className='layer rounded-3' data-bs-toggle="modal" data-bs-target="#exampleModal1"><i className="fa-solid fa-plus"></i></div>
                  </div>
              </div>
              <div className='col-md-4'>
                  <div className='item position-relative'>
                    <img src={pic2} alt="house" className='w-100 rounded-3'/>
                    <div className='layer rounded-3'data-bs-toggle="modal" data-bs-target="#exampleModal2"><i className="fa-solid fa-plus"></i></div>
                  </div>
              </div>
              <div className='col-md-4'>
                  <div className='item position-relative'>
                    <img src={pic3} alt="house" className='w-100 rounded-3'/>
                    <div className='layer rounded-3'data-bs-toggle="modal" data-bs-target="#exampleModal3"><i className="fa-solid fa-plus"></i></div>
                  </div>
              </div>
              <div className='col-md-4'>
                  <div className='item position-relative'>
                    <img src={pic1} alt="house" className='w-100 rounded-3'/>
                    <div className='layer rounded-3'data-bs-toggle="modal" data-bs-target="#exampleModal1"><i className="fa-solid fa-plus"></i></div>
                  </div>
              </div>
              <div className='col-md-4'>
                  <div className='item position-relative'>
                    <img src={pic2} alt="house" className='w-100 rounded-3'/>
                    <div className='layer rounded-3'data-bs-toggle="modal" data-bs-target="#exampleModal2"><i className="fa-solid fa-plus"></i></div>
                  </div>
              </div>
              <div className='col-md-4'>
                  <div className='item position-relative'>
                    <img src={pic3} alt="house" className='w-100 rounded-3'/>
                    <div className='layer rounded-3'data-bs-toggle="modal" data-bs-target="#exampleModal3"><i className="fa-solid fa-plus"></i></div>
                  </div>
              </div>

            </div>
     
      </div>

      {/* BOOOOT STRAAAP MODAAAL */}

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
              <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <img src={pic1} alt="house" className='w-100'/>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
              <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <img src={pic2} alt="house" className='w-100'/>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel3" aria-hidden="true">
              <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <img src={pic3} alt="house" className='w-100'/>
                  </div>
                </div>
              </div>
            </div>
 
    </div>
  
  </>
  
}
