import React from 'react'

export default function Footer() {


  return <>

  <div className='container-fluid'>

        <div className='footer1 row p-5'>

            <div className='col-md-4 text-white text-center py-4'>
                <h3>LOCATION</h3>
                <p className='p-0 m-0 mt-2'>2215 John Daniel Drive</p>
                <p className='p-0 m-0'>Clark, MO 65243</p>
            </div>

            <div className='col-md-4 text-white text-center py-4'>
                <h3>AROUND THE WEB</h3>
                <div className='divIcons mt-2'>
                <i className="fa-brands fa-facebook me-2"></i>
                <i className="fa-brands fa-twitter me-2"></i>
                <i className="fa-brands fa-linkedin-in me-2"></i>
                <i className="fa-solid fa-globe me-2"></i>
            </div>
            </div>

            <div className='col-md-4 text-white text-center py-4'>
                <h3>ABOUT FREELANCER</h3>
                <p className='p-0 m-0 mt-2'>Freelance is a free to use, licensed Bootstrap theme created by Route</p>
            </div>

        </div>
  </div>
   


    <div className='footer2 text-white text-center p-4'>
        <p className='p-0 m-0'>Copyright © Your Website 2024</p>
    </div>

  </>

}
