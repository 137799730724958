import React from 'react'

export default function Contact() {

  document.title = "Contact" ; // yro7 ll index html w y8yer l mktob fl title 

  return <>
  
  <div className='contact myMargin pt-4'>
        
      <div className='container text-center pt-5 portoTitle'>
    
    
        <h2 className='my-3 fw-bold fs-1'>CONTACT SECTION</h2>

        <div className='mb-5 d-flex justify-content-center align-items-center'>
            <div className='shape portoShape'></div>
            <i className="fa-solid fa-star ms-2 me-2"></i>
            <div className='shape portoShape'></div>
        </div>
        
        <form className='mb-5 w-75 m-auto text-start'>
          <input type="text" className='form-control w-100 m-auto mb-4' placeholder='User Name'/>
          <input type="number" className='form-control w-100 m-auto mb-4' placeholder='User Age'/>
          <input type="email" className='form-control w-100 m-auto mb-4' placeholder='User Email'/>
          <input type="password" className='form-control w-100 m-auto mb-4' placeholder='User Password'/>
          <button type="button" className='btn btn-secondary'>Send Message</button>
        </form>


      </div>
  </div>
  
  </>
}
