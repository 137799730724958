import React from 'react'

export default function About() {


  document.title = "About" ; // yro7 ll index html w y8yer l mktob fl title 

  return <>
    
     <div className='about pb-4 myMargin pt-4'>
        
        <div className='container text-center pt-5 pb-5 text-white'>

            <h2 className='my-3 fw-bold fs-1 pt-5'>ABOUT COMPONENT</h2>

            <div className='mb-3 d-flex justify-content-center align-items-center'>
                <div className='shape bg-light'></div>
                <i className="fa-solid fa-star ms-2 me-2"></i>
                <div className='shape bg-light'></div>
            </div>

           <div className='row pb-5 gy-4'>
            <div className='col-md-6 px-5'><p>Freelancer is a free bootstrap theme created by Route. The download includes the complete source files including HTML, CSS, and JavaScript as well as optional SASS stylesheets for easy customization. </p></div>
            <div className='col-md-6 px-5 pb-5'><p>Freelancer is a free bootstrap theme created by Route. The download includes the complete source files including HTML, CSS, and JavaScript as well as optional SASS stylesheets for easy customization. </p></div>
           </div>
        
       </div>
    
    </div>
    
  </>
}
